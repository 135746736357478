@import '~antd/dist/antd.css';
@import './colors.scss';


html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $textColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pt20 {
  padding-top: 20px;
}

.normalPage{
  background-color: #fff;
  padding: 20px;
}

.tableDetailButton{
  color:$textColor;
}

.tableEditButton{
  color:$greenColor;
}

.tableDeleteButton{
  color:$redColor;
}

.tableTotalItem{
  position:absolute;
  left:20px;
}


.base-loading-container,
.base-loading-container > div {
  height: 100%;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #ffe9b0;
}

.ant-menu-light .ant-menu-item:hover{
  background-color: #ffe9b0;
  color:$textColor;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover{
  color:$textColor;
}

.ant-menu-inline .ant-menu-item::after{
  border-right-width: 0px;
}

.ant-menu-light .ant-menu-submenu-title:hover{
  color:$textColor;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
  color:$textColor;
}

.ant-menu-submenu-selected{
  color:$textColor;
}


.ant-btn:hover, .ant-btn:focus{
  color:$tintColor;
  border-color: $tintColor;
}

.ant-btn-dangerous.ant-btn-primary{
  border-color: $redColor;
  background: $redColor;
}

.ant-btn-primary
{
  border-color: $tintColor;
  background: $tintColor;
  color:#fff;
  &:hover{
    background: $tintColor;
    color:#fff;
  }
  &:focus{
    background: $tintColor;
    color:#fff;
  }
}

.ant-table-thead > tr > th{
  background:#fef8e6;
}

.ant-pagination-item-active{
  border-color: $tintColor;
  background-color: $tintColor;
  &:hover{
    border-color: $tintColor;
    background-color: $tintColor;
    a{
      color:#fff;
    }
  }
  a{
    color:#fff;
  }
}

.ant-tree-checkbox:hover .ant-tree-checkbox-inner{
  border-color: $tintColor;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner{
  border-color: $tintColor;
  background-color: $tintColor;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after{
  background-color: $tintColor;
}

.ant-tree-checkbox-checked::after{
  border-color: $tintColor;
}

.ant-form-inline .ant-form-item{
  margin-bottom: 16px;
}

.normarlEditButton{
  color:$greenColor;
  background-color:#f6f7f8;
  border-color: #f6f7f8;
  &:hover{
    color:$greenColor;
    background-color:#f6f7f8;
    border-color: #f6f7f8;
  }
  &:focus{
    color:$greenColor;
    background-color:#f6f7f8;
    border-color: #f6f7f8;
  }
}

.normarlDeleteButton{
  color:$redColor;
  background-color:#f6f7f8;
  border-color: #f6f7f8;
  &:hover{
    color:$redColor;
    background-color:#f6f7f8;
    border-color: #f6f7f8;
  }
  &:focus{
    color:$redColor;
    background-color:#f6f7f8;
    border-color: #f6f7f8;
  }
}