@import '../../colors.scss';

.titleValueLabel{
  .titleText{
    font-size: 0.8rem;
    color:$subTitleColor;
    line-height: 1.6rem;
  }
  .bigText{
    color:$textColor;
    font-size: 1rem;
  }
}
