@import '../../../../colors.scss';
@import '../../../../baseStyle.scss';

.accessoryList{
  display: flex;
  flex-wrap: wrap;
}

.accessoryItem{
  @extend .centerFlex;
  font-size:0.8rem;
  margin-left: 20px;
  margin-top: 10px;
  .mediaView{
    width:8rem;
    height:6rem;
    overflow: hidden;
    border:1px solid #eee;
    .videoView{
      background-color:#000;
      width: 100%;
      height: 100%;
    }
    .image{
      width: auto;
      height: 100%;
      margin: auto;
      // height: 100%;
    }
  }
  .downloadIcon{
    width: 1.5rem;
    height:1.5rem;
    position: relative;
    bottom:-25%;
    left:-25%;
  }
}
