.timeInfo{
  margin-top: 20px;
}

.col{
  padding:20px;
}


.chargeBtn{
  position: absolute;
  top: 0%;
  right: 20px;
  margin-top: 20px;
  border-radius: 20px;
  font-size:0.6rem;
  height:26px;
  color: #FFB03B;
  border-color: #FFB03B;
  padding:4px 10px;
}
