@import '../../colors.scss';

.ant-tree-title{
  color:#000;
  font-size: 1.2rem;
  height: 2rem;
  display: inline-block;
}

.departmentTree{
  .arrowIcon{
    color: #00adf9;
    font-size: 0.7rem;
  }
  .addIcon{
    margin-left: 1em;
    width: 1.8rem;
    height: 1.8rem;
  }
  .editIcon{
    margin-left: 1em;
    width: 1rem;
    height: 1rem;
  }
  .smallAddIcon{
    margin-left: 1em;
    width: 1.2rem;
    height: 1.2rem;
  }
}