@import '../../baseStyle.scss';

.companyInfoSetting{
  .logoImage{
    width:4rem;
    height:4rem;
  }
  .infoView{
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
  }
}