@import '../../colors.scss';

.taskList{
  .taskTitle{
    color:$tintColor;
  }
}

.noPadding{
  padding:0;
}

.taskStatusInitView{
  background-color: $greenColor;
  color:#fff;
  display: inline-block;
  min-width: 4.8rem;
  height:1.6rem;
  line-height:1.6rem;
  border-radius:1rem;
  padding:0px 16px;
  display:inline-block;
  text-align:center;
}

.taskStatusRunningView{
  @extend .taskStatusInitView;
  background-color: $tintColor;
}

.taskStatusFinishView{
  @extend .taskStatusInitView;
  background-color: $brownColor;
}
