@import '../../../../../colors.scss';
@import '../../../../../baseStyle.scss';

.taskMediaItem{
  @extend .centerFlex;
  font-size:0.8rem;
  padding:20px;
  .mediaView{
    width:8rem;
    height:6rem;
    overflow: hidden;
    .videoView{
      background-color:#000;
      width: 100%;
      height: 100%;
      .playIcon{
        width: 2rem;
        height:2rem;
        position: relative;
        top:60%;
        left:70%;
      }
    }
    .image{
      width: 100%;
      height: 100%;
    }
  }
  .mediaInfoView{
    margin-left: 20px;
    display: flex;
    flex:1;
    height:4.5rem;
    flex-direction: column;
    justify-content: space-between;
    .mediaSizeInfoView{
      @extend .centerFlex;
      justify-content: space-between;
      .downloadIcon{
        width: 2rem;
        height: 2rem;
        margin-left: 20px;
      }
      .titleText{
        color:$textColor;
        font-size:1rem;
      }
      .sizeText{
        color: $subTitleColor;
        font-size:1rem;
      }
    }
    .userText{
      font-size: 1rem;
    }
  }
}

.ant-image{
  width: 100%;
  height:100%;
}