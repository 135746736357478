@import '../../../../colors.scss';
@import '../../../../baseStyle.scss';

.taskLogItem{
  display:flex;
  border-left: 1px solid $subTitleColor;
  padding:5px 0px 40px 0px;
  font-size: 0.8rem;
  color:$subTitleColor;
  &.active{
    border-left: 0px solid $subTitleColor;
  }
  .point{
    background-color:$subTitleColor;
    width:0.6rem;
    height:0.6rem;
    border-radius:0.3rem;
    position: relative;
    left:-0.3rem;
    top:-0.3rem;
    margin-right: 10px;
    &.active{
      background-color: $tintColor;
    }
  }
  .time{
    margin-bottom: 20px;
  }
  .handlerView{
    margin-bottom: 20px;
    .handlerText{
      color:$textColor;
    }
    .handler{
      color:$textColor;
    font-size: 1rem;
      margin: 0px 10px;
    }
  }
  .updateContent{
    color:$textColor;
  }
  .infoItem{
    margin: 10px 0px;
    .title{
      color:$subTitleColor;
      font-size: 0.7rem;
    }
    .value{
      color:$textColor;
    }
  }
  
  .liveInfoItem{
    .livingText{
      font-size: 0.7rem;
      padding:2px 20px;
      background-color:$tintColor;
      color:#fff;
      border-radius: 999px;
      margin-left: 20px;
    }
    .liveInfoEndView{
      margin-top: 30px;
      width: 500px;
      background-color: #fff;
      border-radius: 20px;
      border: 1px solid $tintColor;
      .liveInfoEndHeader{
        padding:20px;
        .desView{
          @extend .centerFlex;
          justify-content:space-between;
        }
        .editIcon{
          width:1.2rem;
          height:1.2rem;
          margin-left: 30px;
        }
        .arrowIcon{
          width:0.6rem;
          height:1rem;
          margin-left: 30px;
        }
      }
      .mediaView{
        border-top: 1px solid #e8e8e8;
      }
    }
  }
  
  .localInfoItem{
    border: 1px solid $tintColor;
  }
}
