@import '../../colors.scss';

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.siteLayout {
  height: 100%;
}

.siteLayout .siteLayoutBackground {
  background: $tintColor2;
}

.siteLayout .content {
  background: #fff;
}

.sysInfoView {
  .logoDiv{
    height: 32px;
  }
  .logoImage{
    width:32px;
    height: 32px;
  }

.sysName{
  margin-left: 10px;
  font-weight: 500;
}

  width: 200px;
  background: $tintColor2;
  padding: 16px;
  // background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menuIcon{
  width:16px;
  height: 16px;
}
