@import '../../../colors.scss';

.ant-tabs-top > .ant-tabs-nav{
  margin:0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active{
  background-color:$tintColor;
  color:#fff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color:#fff;
}

.userDetail{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .userDetailInfo{
    padding:20px;
  }
}
