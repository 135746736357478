@import '../../colors.scss';

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
  color: #b8b7b7;
}
.ant-card-meta-description {
  color: #54667a;
  font-size: 20px;
}
.card {
  width: 300;
  margin-top: 16;
  .title{
    font-size:0.8rem;
  }
  .avatar {
    height: 50px;
    width: 50px;
  }
  .description {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: $textColor;
  }
  .timetype {
    font-size: 0.8rem;
    margin-left: 5px;
  }
}
