@import '../../colors.scss';
@import '../../baseStyle.scss';

.appUserTable{
  .normalStatusView{
    background-color: $greenColor;
    color:#fff;
    height:1.6rem;
    line-height:1.6rem;
    border-radius:1rem;
    padding:0px 16px;
    display:inline-block
  }
  .freezeStatusView{
    background-color: $redColor;
  }
}

.importUserModal{
  .borderView{
    @extend .centerFlex;
    flex-direction: column;
    border: 1px dashed #ccc;
    padding:20px;
  }
  .fileIcon{
    font-size: 3rem;
    margin: 20px;
  }
  .fileName{
    color:$textColor;
    font-size: 1.2rem;
  }
  .tip{
    color:$titleColor;
  }
  .link{
    color:$tintColor;
  }
  .importUserModalUpload{
    width:100%;
    display: inline-block;
    .ant-upload.ant-upload-select{
      width:100%;
    }
  }
}
// .ant-upload.ant-upload-select{
//   width:100%;
// }
