@import './../../colors.scss';

.headerTitle{
  display: flex;
  align-items:flex-start;
  color:$textColor;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 30px;
  .colorBlock{
    display:inline-block;
    width:5px;
    height:24px;
    margin-right: 5px;
    background-color:$tintColor;
  }
}