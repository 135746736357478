@import '../../colors.scss';
@import '../../baseStyle.scss';

.loginContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  min-width: 300px;
  .loginCard{
    background:#000;
    padding:30px 20px;
    .sysInfo{
      text-align: center;
      margin-bottom: 30px;
      .logo{
        width: 60px;
        height: 64px;
      }
      .sysName{
        margin: 10px 0px;
        color: $tintColor2;
      }
    }
    .ant-input{
      font-size:16px;
    }
    .loginInput{
      padding: 10px;
      font-size:16px;
    }
    .inputIcon{
      width:20px;
      height:24px;
      margin-right: 10px;
    } 
  }

  .form {
    width: 300px;
  }
  
  .tips {
    @extend .centerFlex;
    justify-content:space-between;
    margin: 10px 0px;
    color:#fff;
    font-size: 12px;
  }

  .loginButton{
    width:100%;
    height:45px;
    border-color: $tintColor2;
    background: $tintColor2;
    color: $textColor;
    font-size: 16px;
    &:hover{
      border-color: $tintColor2;
      background: $tintColor2;
      color: $textColor;
    }
    &:focus{
      border-color: $tintColor2;
      background: $tintColor2;
      color: $textColor;
    }
  }
}

