$tintColor:#f18023;
$tintColor2:#fbc840;
$titleColor:#626262;
$textColor:#333333;
$contentColor:#626261;
$subTitleColor:#ad9f8a;
$pageGrayColor:#e9e9eb;
$brownColor: #624f23;
$greenColor: #80c989;
$redColor: #e5222a;
$deepGrayColor: #9b9b9b;